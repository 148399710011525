<template>
  <div id="waterSupply">
    <div class="p-3">
      <div><app-title-content></app-title-content></div>
      <div class="row my-3">
        <div class="col-10">
          <select2
            :options="typeList"
            v-model="typeSelect"
            class="select-custom"
            placeholder="ประเภทของระบบประปา"
            :settings="{
              language: {
                noResults: function () {
                  return 'ไม่พบข้อมูล';
                },
              },
              allowClear: 'true',
            }"
          ></select2>
        </div>
        <div class="col-2">
          <button
            type="submit"
            class="btn btn-save w-100"
            @click="getWaterSupply"
          >
            ค้นหา
          </button>
        </div>
      </div>
      <div class="box-shadow p-4 mt-3">
        <div class="d-flex justify-content-between">
          <div class="d-flex align-items-baseline">
            <div class="fontsize-h3 weight-700 mr-3 font-darkblue">
              รายการข้อมูลประปาหมู่บ้าน
            </div>
            <div class="fontsize-h5">จำนวน {{ total | toCommas }} รายการ</div>
          </div>
          <div class="d-flex">
            <button @click="AddNewWaterSupply" class="btn btn-addlist mr-3">
              <font-awesome-icon
                :icon="['fas', 'plus-circle']"
                class="font-white mr-2"
              />
              เพิ่มข้อมูลประปาหมู่บ้าน
            </button>
            <b-overlay :show="isExport">
              <button class="btn btn-download" @click="exportExcel()">
                <iconify icon="uiw:file-excel" class="fontsize-h4 mr-2" />
                Export to Excel
              </button>
            </b-overlay>
          </div>
        </div>
        <div class="mt-3">
          <b-table
            id="my-table"
            hover
            borderless
            :no-border-collapse="true"
            :items="items"
            :fields="fields"
            show-empty
            :per-page="perPage"
            class="table-custom box-shadow"
            :busy.sync="isBusy"
          >
            <template v-slot:empty="">
              <div class="text-center fontsize-h4 my-4">ไม่พบข้อมูล</div>
            </template>

            <div slot="table-busy" class="text-center font-blueinfo my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="pl-2">กำลังโหลด...</strong>
            </div>

            <template v-slot:head()="data">
              <div
                :class="
                  data.field.align === 'center'
                    ? 'text-center'
                    : data.field.align === 'right'
                    ? 'text-right'
                    : 'text-left'
                "
              >
                {{ data.label }}
              </div>
            </template>

            <template v-slot:cell(index)="data">
              <div class="text-center">
                {{ (currentPage - 1) * perPage + (data.index + 1) + "." }}
              </div>
            </template>
            <template #cell(edit)="row">
              <div class="text-center">
                <font-awesome-icon
                  :icon="['fas', 'edit']"
                  @click="editRow(row.item.pwaProjectId)"
                  class="font-yellow cursor-pointer"
                />
              </div>
            </template>
            <template #cell(delete)="row">
              <div class="text-center">
                <font-awesome-icon
                  :icon="['fas', 'trash']"
                  @click="deleteRow(row.item.pwaProjectId)"
                  class="font-reddanger cursor-pointer"
                />
              </div>
            </template>
          </b-table>
          <b-pagination
            class="pagination-custom mt-3"
            v-model="currentPage"
            :total-rows="total"
            :per-page="perPage"
            aria-controls="my-table"
            align="right"
            @change="changePage($event)"
          ></b-pagination>
        </div>
      </div>
    </div>

    <ModalAdd
      :modalShow="isShowModal"
      :isEdit="isEdit"
      :pwaId="pwaId"
      @change="isShowModal = $event"
      @reloadDataGrid="getWaterSupply"
    ></ModalAdd>
  </div>
</template>

<script>
import { WaterSupplyService, FilterService } from "@/services/main.service.js";
import ModalAdd from "./AddWaterSupply.vue";
export default {
  name: "water-supply",
  components: {
    ModalAdd,
  },
  data() {
    return {
      perPage: 50,
      currentPage: 1,
      isExport: false,
      total: 0,
      pwaId: null,
      fields: [],
      items: [],
      typeSelect: null,
      supplyName: null,
      isShowModal: false,
      statusList: [],
      typeList: [],
      supplyStatus: null,
      supplyType: null,
      isBusy: false,
      isEdit: false,
      apiURL: process.env.VUE_APP_BASE_API_DOWNLOAD,
      fields: [
        {
          key: "index",
          label: "ลำดับ",
          sortable: false,
          align: "center",
        },
        {
          key: "villageName",
          label: "หมู่บ้าน",
          sortable: false,
        },
        {
          key: "villageNo",
          label: "หมู่",
          sortable: false,
        },
        {
          key: "tambolName",
          label: "ตำบล",
          sortable: false,
        },
        {
          key: "amphorName",
          label: "อำเภอ",
          sortable: false,
        },
        {
          key: "provinceName",
          label: "จังหวัด",
          sortable: false,
        },
        {
          key: "sourceTypeName",
          label: "ประเภท / ขนาด",
          sortable: false,
        },
        {
          key: "lat",
          label: "ละติจูด",
          sortable: false,
        },
        {
          key: "long",
          label: "ลองจิจูด",
          sortable: false,
        },
        {
          key: "edit",
          label: "แก้ไข",
          sortable: false,
          align: "center",
        },
        {
          key: "delete",
          label: "ลบ",
          sortable: false,
          align: "center",
        },
      ],
    };
  },
  async created() {
    await this.getWaterSupply();

    await FilterService.getFilterSourceType().then((res) => {
      this.typeList = res.map((m) => {
        return { ...m };
      });
    });

    await FilterService.getFilterStatusType().then((res) => {
      this.statusList = res.map((m) => {
        return { ...m };
      });
    });
  },
  methods: {
    getWaterSupply() {
      this.isBusy = true;
      const param = {
        PWASourceTypeId: this.typeSelect,
        NumRow: this.perPage,
        NumPage: this.currentPage,
        PWAStatusid: parseInt(this.supplyStatus) || null,
        PWAProjectTypeId: parseInt(this.supplyType) || null,
      };
      WaterSupplyService.searchWater(param)
        .then((res) => {
          this.items = res.data.map((m) => {
            return {
              ...m,
            };
          });
          this.total = res.total;
        })
        .catch((err) => {
          this.items = [];
          this.currentPage = 1;
          this.total = 0;
        })
        .finally((f) => {
          this.isBusy = false;
        });
    },
    async changePage(event) {
      this.currentPage = event;

      await this.getWaterSupply();
    },
    AddNewWaterSupply() {
      this.isEdit = false;
      this.isShowModal = true;
    },
    async exportExcel() {
      this.isExport = true;
      const param = {
        PWASourceTypeId: this.typeSelect,
        PWAStatusid: parseInt(this.supplyStatus) || null,
        PWAProjectTypeId: parseInt(this.supplyType) || null,
      };
      await WaterSupplyService.ExportExcelWater(param)
        .then((res) => {
          if (res) {
            let file = `${this.apiURL}/${res.path}`;
            try {
              window.open(file, "_blank");
              this.isExport = false;
            } catch (e) {
              this.alertDownloadFail();
              this.isExport = false;
            }
          }
        })
        .catch((err) => {
          this.alertDownloadFail();
          this.isExport = false;
        });
    },
    editRow(id) {
      this.pwaId = id;
      this.isEdit = true;
      this.isShowModal = true;
    },
    async deleteRow(id) {
      this.alertDeleteForm().then((result) => {
        if (result) {
          WaterSupplyService.deletetWater(id).then((res) => {
            if (res.resData.status) {
              this.alertSuccess("ลบรายการสำเร็จ");
              this.getWaterSupply();
            }
          });
        }
      });
    },
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
};
</script>

<style lang="scss">
.custom-info-window {
  background-color: wheat;
}
</style>
