var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"waterSupply"}},[_c('div',{staticClass:"p-3"},[_c('div',[_c('app-title-content')],1),_c('div',{staticClass:"row my-3"},[_c('div',{staticClass:"col-10"},[_c('select2',{staticClass:"select-custom",attrs:{"options":_vm.typeList,"placeholder":"ประเภทของระบบประปา","settings":{
            language: {
              noResults: function () {
                return 'ไม่พบข้อมูล';
              },
            },
            allowClear: 'true',
          }},model:{value:(_vm.typeSelect),callback:function ($$v) {_vm.typeSelect=$$v},expression:"typeSelect"}})],1),_c('div',{staticClass:"col-2"},[_c('button',{staticClass:"btn btn-save w-100",attrs:{"type":"submit"},on:{"click":_vm.getWaterSupply}},[_vm._v(" ค้นหา ")])])]),_c('div',{staticClass:"box-shadow p-4 mt-3"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"d-flex align-items-baseline"},[_c('div',{staticClass:"fontsize-h3 weight-700 mr-3 font-darkblue"},[_vm._v(" รายการข้อมูลประปาหมู่บ้าน ")]),_c('div',{staticClass:"fontsize-h5"},[_vm._v("จำนวน "+_vm._s(_vm._f("toCommas")(_vm.total))+" รายการ")])]),_c('div',{staticClass:"d-flex"},[_c('button',{staticClass:"btn btn-addlist mr-3",on:{"click":_vm.AddNewWaterSupply}},[_c('font-awesome-icon',{staticClass:"font-white mr-2",attrs:{"icon":['fas', 'plus-circle']}}),_vm._v(" เพิ่มข้อมูลประปาหมู่บ้าน ")],1),_c('b-overlay',{attrs:{"show":_vm.isExport}},[_c('button',{staticClass:"btn btn-download",on:{"click":function($event){return _vm.exportExcel()}}},[_c('iconify',{staticClass:"fontsize-h4 mr-2",attrs:{"icon":"uiw:file-excel"}}),_vm._v(" Export to Excel ")],1)])],1)]),_c('div',{staticClass:"mt-3"},[_c('b-table',{staticClass:"table-custom box-shadow",attrs:{"id":"my-table","hover":"","borderless":"","no-border-collapse":true,"items":_vm.items,"fields":_vm.fields,"show-empty":"","per-page":_vm.perPage,"busy":_vm.isBusy},on:{"update:busy":function($event){_vm.isBusy=$event}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"text-center fontsize-h4 my-4"},[_vm._v("ไม่พบข้อมูล")])]},proxy:true},{key:"head()",fn:function(data){return [_c('div',{class:data.field.align === 'center'
                  ? 'text-center'
                  : data.field.align === 'right'
                  ? 'text-right'
                  : 'text-left'},[_vm._v(" "+_vm._s(data.label)+" ")])]}},{key:"cell(index)",fn:function(data){return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s((_vm.currentPage - 1) * _vm.perPage + (data.index + 1) + ".")+" ")])]}},{key:"cell(edit)",fn:function(row){return [_c('div',{staticClass:"text-center"},[_c('font-awesome-icon',{staticClass:"font-yellow cursor-pointer",attrs:{"icon":['fas', 'edit']},on:{"click":function($event){return _vm.editRow(row.item.pwaProjectId)}}})],1)]}},{key:"cell(delete)",fn:function(row){return [_c('div',{staticClass:"text-center"},[_c('font-awesome-icon',{staticClass:"font-reddanger cursor-pointer",attrs:{"icon":['fas', 'trash']},on:{"click":function($event){return _vm.deleteRow(row.item.pwaProjectId)}}})],1)]}}])},[_c('div',{staticClass:"text-center font-blueinfo my-2",attrs:{"slot":"table-busy"},slot:"table-busy"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',{staticClass:"pl-2"},[_vm._v("กำลังโหลด...")])],1)]),_c('b-pagination',{staticClass:"pagination-custom mt-3",attrs:{"total-rows":_vm.total,"per-page":_vm.perPage,"aria-controls":"my-table","align":"right"},on:{"change":function($event){return _vm.changePage($event)}},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])]),_c('ModalAdd',{attrs:{"modalShow":_vm.isShowModal,"isEdit":_vm.isEdit,"pwaId":_vm.pwaId},on:{"change":function($event){_vm.isShowModal = $event},"reloadDataGrid":_vm.getWaterSupply}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }